.Dashboard {
    font-family: 'PT Sans', sans-serif;
}


.Dashboard .header {
	background-repeat: no-repeat;
	background-position-x: 25%;
	background-position-y: 100%;
}

.Dashboard .header-text {
	background: rgba(255, 255, 255, 0.7);
}
.Dashboard .header-text.line1 {
	color: #4595D2;
}
.Dashboard .header-text.line2 {
	color: #ED1A3B;
}


.Dashboard .details {
	background-color: #f0f0f0;
	padding: 20px 30px;
	margin-left: -30px;
	margin-right: -30px;
}


.Dashboard .framed {
	background-color: #fff;
	border: 1px solid #ccc;

	margin-top: 1em;
	margin-bottom: 1em;

	padding-top: 0.5em;
	padding-bottom: 1em;
}

@media (min-width: 992px) {  /* Bootstrap @screen-md-min */
	.Dashboard .ds {
		width: 47.5%;
		margin-right: 2.5%;
	}
}


/* капитель */

.Dashboard .smcp {
	text-transform: uppercase;
	letter-spacing: 0.07em;
}

.Dashboard .smcp:first-letter {
	font-size: 125%;
	letter-spacing: 0.07em;
}

.Dashboard .allcaps {
	text-transform: uppercase;
	letter-spacing: 0.07em;
}
