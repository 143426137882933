.DataProvider .box.normal {
	opacity: 1;
}

.DataProvider .box.loading {
	opacity: 0.5;

	transition-property: opacity;
	transition-duration: 125ms;
	transition-timing-function: ease;
}
